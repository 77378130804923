<template lang="pug">
    li.site-navigation__item
        a(:href="url").site-navigation__link
            span.text {{page}}
            span.line.right
            span.line.top
            span.line.left
            span.line.bottom
</template>

<script>
    export default {
        name: 'HeaderLink',
        props: {
            url: {
                type: String,
            },
            page: {
                type: String,
            },
        },
    }
</script>

<style lang="scss">

    @import "../assets/scss/general.scss";

    $thick : 2px;
    $pad : 0.7em;
    $extra : calc(#{$pad} * 1.2);
    $color : #F4FDFF;

    .site-navigation__link {
        cursor: pointer;
        color: #F4FDFF;
        padding: $pad $extra;
        display: inline-block;
        border: $thick solid transparent;
        position: relative;
        font-size: 20px;

        .text {
            transform: translate3d(0, 0.3em,0);
            display: block;
            transition: transform 0.4s cubic-bezier(.2,0,0,1) 0.4s;
        }

        &:after {
            position: absolute;
            content: '';
            bottom: -$thick;
            left: $extra;
            right: $extra;
            height: $thick;
            background: $color;
            z-index: -1;
            transition:
                    transform 0.8s cubic-bezier(1,0,.37,1) 0.2s,
                    right 0.2s cubic-bezier(.04,.48,0,1) 0.6s,
                    left 0.4s cubic-bezier(.04,.48,0,1) 0.6s;
            transform-origin: left;
        }
    }

    .line {
        position: absolute;
        background: $color;

        &.right,
        &.left {
            width: $thick;
            bottom: -$thick;
            top: -$thick;
            transform: scale3d(1,0,1);
        }

        &.top,
        &.bottom {
            height: $thick;
            left: -$thick;
            right: -$thick;
            transform: scale3d(0,1,1);
        }

        &.right {
            right: -$thick;
            transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.23s;
            transform-origin: top;
        }

        &.top {
            top: -$thick;
            transition: transform 0.08s linear 0.43s;
            transform-origin: left;
        }

        &.left {
            left: -$thick;
            transition: transform 0.08s linear 0.51s;
            transform-origin: bottom;
        }

        &.bottom {
            bottom: -$thick;
            transition: transform 0.3s cubic-bezier(1,0,.65,1.01);
            transform-origin: right;
        }
    }

    .site-navigation__link:hover,
    .site-navigation__link:active {

        .text {
            transform: translate3d(0,0,0);
            transition: transform 0.6s cubic-bezier(.2,0,0,1) 0.4s;
        }

        &:after {
            transform: scale3d(0,1,1);
            right: -$thick;
            left: -$thick;
            transform-origin: right;
            transition:
                    transform 0.2s cubic-bezier(1,0,.65,1.01) 0.17s,
                    right 0.2s cubic-bezier(1,0,.65,1.01),
                    left 0s 0.3s;
        }

        .line {
            transform: scale3d(1,1,1);

            &.right {
                transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.2s;
                transform-origin: bottom;
            }

            &.top {
                transition: transform 0.08s linear 0.4s;
                transform-origin: right;
            }

            &.left {
                transition: transform 0.08s linear 0.48s;
                transform-origin: top;
            }

            &.bottom {
                transition: transform 0.5s cubic-bezier(0,.53,.29,1) 0.56s;
                transform-origin: left;
            }
        }
    }

    @media (max-width: 900px) {

        .site-navigation__link:after {
            background: transparent;
        }
    }

</style>